import React, { type FunctionComponent } from 'react';
import { type ContentCardCommonFieldsFragment } from '../../../__generated__/graphql-client-types';
import { RelatedArticle } from '../related-article/related-article.component';

const isGeneralContent = (schemaType?: string) => schemaType?.startsWith('general-landing');

export type ArticleContentCardProps = ContentCardCommonFieldsFragment & { rootSchema?: string };

export const ArticleContentCard: FunctionComponent<ArticleContentCardProps> = ({ detail, url, title, image, rootSchema }) => {
	const video = detail?.['video'] ?? null;
	return (
		<RelatedArticle
			url={url}
			title={title}
			image={image}
			video={video}
			largeWidth={isGeneralContent(rootSchema) ? 'quarter' : 'half'}
		/>
	);
};
