import React from 'react';
import { type ContentComponent, type EmailSubscribeFormPlaceholderContent } from '../../../../types/construct.types';
import { EmailSignUpForm } from '../../../email-sign-up-form/email-sign-up-form.component';
import { addContentHandler } from '../add-content-handler';

export const EmailSubscribePlaceholder: ContentComponent<EmailSubscribeFormPlaceholderContent> = () => (
	<div className="pa5">
		<EmailSignUpForm />
	</div>
);

addContentHandler('content-support-pages', 'ObjectContent', 'placeholder-email-subscribe-form', EmailSubscribePlaceholder);
