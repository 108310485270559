import React, { type FunctionComponent } from 'react';
import { type StyledLinkProps, StyledLink } from './styled-link.component';

export type MaybeStyledLinkProps = {
	url?: string | null;
	children: React.ReactNode;
} & Omit<StyledLinkProps, 'url'>;

/**
 * A styled link that accepts an optional url.  If no url is supplied then the links
 * children will be rendered with no containing link.
 */
export const MaybeStyledLink: FunctionComponent<MaybeStyledLinkProps> = ({ url, children, ...rest }) => {
	return url ? (
		<StyledLink url={url} {...rest}>
			{children}
		</StyledLink>
	) : (
		<>{children}</>
	);
};
