import {
	type ContentCardValueFieldsFragment,
	type ContentDocumentFieldsFragment,
	type ContentImageValueFieldsFragment,
	type ContentLinkValueFieldsFragment,
	type ContentListValueFieldsFragment,
	type ContentObjectValueFieldsFragment,
	type ContentRichTextValueFieldsFragment,
	type ContentTextValueFieldsFragment
} from '../../__generated__/graphql-client-types';
import {
	type CardContent,
	type Content,
	type Fields,
	type ImageContent,
	type LinkContent,
	type ListContent,
	type ObjectContent,
	type RichTextContent
} from '../../types/construct.types';

type ContentValue = ContentDocumentFieldsFragment['values'][number];
type ContentFunction = {
	(kind: string, value: { __typename?: string }): Content | undefined;
	call: (helper: ConstructHelper, kind: string, value: { __typename?: string }) => Content | undefined;
};
type TypeNames = Exclude<ContentValue['__typename'], undefined>;
type ContentFunctions = {
	[key in TypeNames]: ContentFunction | undefined;
};

const isNotNullish = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined;

const getEmptyObjectContent = (): ObjectContent => ({ type: 'ObjectContent', fields: {} });

const isObjectContent = (content: Content | undefined): content is ObjectContent => {
	return typeof content === 'object' && content.type === 'ObjectContent';
};

/**
 * Use to get the content object based on the given content query results.
 */
export class ConstructHelper {
	private contentMappers: ContentFunctions = {
		ContentCardValue: this.mapToCard,
		ContentImageValue: this.mapToImage,
		ContentLinkValue: this.mapToLink,
		ContentListValue: this.mapToList,
		ContentObjectValue: this.mapToObject,
		ContentRichTextValue: this.mapToRichText,
		ContentTextValue: this.mapToText
	};

	constructor(private readonly contentDocument: ContentDocumentFieldsFragment) {}

	getContent(): ObjectContent {
		const content = this.mapToContent(this.contentDocument.rootIndex);
		return isObjectContent(content) ? content : getEmptyObjectContent();
	}

	getSchemaType(): string {
		return this.contentDocument.kinds[this.contentDocument.rootIndex];
	}

	/**
	 * Recursively build the content object to represent the original structure. Uses __typename values from
	 * the resolved content, and creates objects defined in construct.types.ts, with `type` values similar to
	 * __typename values. Not using __typename because the `Content` types for are not defined in graphql schema.
	 */
	private mapToContent(index: number): Content | undefined {
		const kind = this.contentDocument.kinds[index];
		const value = this.contentDocument.values[index];
		const method = this.contentMappers[String(value.__typename)];
		return method?.call(this, kind, value);
	}

	// A card may have nested fields; if not, it is an object with no children (a leaf node).
	private mapToCard(kind: string, value: ContentCardValueFieldsFragment): CardContent {
		const { fieldIndices, ...common } = value;
		const content: CardContent = { type: 'CardContent', kind, ...common, cardFields: this.mapToFields(fieldIndices) };
		return content;
	}

	private mapToImage(kind, value: ContentImageValueFieldsFragment): ImageContent {
		return { type: 'ImageContent', kind, image: value.image };
	}

	private mapToLink(kind, value: ContentLinkValueFieldsFragment): LinkContent {
		return { type: 'LinkContent', kind, ...value };
	}

	private mapToList(kind, value: ContentListValueFieldsFragment): ListContent {
		return { type: 'ListContent', kind, items: this.mapToFieldList(value.itemIndices) };
	}

	private mapToObject(kind, value: ContentObjectValueFieldsFragment): ObjectContent {
		return { type: 'ObjectContent', kind, fields: this.mapToFields(value.fieldIndices) };
	}

	private mapToRichText(kind, value: ContentRichTextValueFieldsFragment): RichTextContent {
		return { type: 'RichTextContent', kind, richText: value.richText };
	}

	private mapToText(kind, value: ContentTextValueFieldsFragment): string {
		return value.text;
	}

	/**
	 * Map field indices to field values for the given fields index, excluding fields with excluded value types.
	 */
	private mapToFields(indices: number[] | null): Fields {
		return (
			indices?.reduce((accum, fieldIndex) => {
				const fieldName = this.contentDocument.fieldNames[fieldIndex];
				accum[fieldName] = this.mapToContent(fieldIndex);
				return accum;
			}, {}) ?? {}
		);
	}

	private mapToFieldList(indices: number[] | null): Content[] {
		return indices?.map((fieldIndex) => this.mapToContent(fieldIndex)).filter(isNotNullish) ?? [];
	}
}
