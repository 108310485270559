import React from 'react';
import { type ContentComponent, type SaleTileListSection } from '../../../../types/construct.types';
import { addContentHandler } from '../add-content-handler';

export const SalesTileListSection: ContentComponent<SaleTileListSection> = ({ content, renderer, options }) => {
	const { sale_tiles_heading, sale_tiles } = content.fields;
	return (
		sale_tiles?.items.length > 0 && (
			<div className="w-100">
				{sale_tiles_heading && <h4 className="tc-ns">{sale_tiles_heading}</h4>}
				{renderer.render(sale_tiles, undefined, { ...options, CardContent: { ...options?.CardContent, layout: 'two-column' } })}
			</div>
		)
	);
};

addContentHandler('sale-tile-list-section', 'ObjectContent', 'sale-tile-list-section', SalesTileListSection);
