import React, { createContext, type FunctionComponent, type PropsWithChildren, useState } from 'react';

export type ProductStickyNavContextState = {
	currentActiveSection: string;
	shouldShowATC: boolean;
	setCurrentActiveSection: (currentActiveSection: string) => void;
	setShouldShowATC: (shouldShowATC: boolean) => void;
	sectionToOpen: string;
	setSectionToOpen: (sectionToOpen: string) => void;
};

const initialState: ProductStickyNavContextState = {
	currentActiveSection: '',
	shouldShowATC: false,
	setCurrentActiveSection: () => {},
	setShouldShowATC: () => {},
	sectionToOpen: '',
	setSectionToOpen: () => {}
};

export const ProductStickyNavContext = createContext<ProductStickyNavContextState>(initialState);

export const ProductStickyNavContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
	const [currentActiveSection, setCurrentActiveSection] = useState('');
	const [shouldShowATC, setShouldShowATC] = useState(false);
	const [sectionToOpen, setSectionToOpen] = useState<string>('');
	const value = {
		currentActiveSection,
		setCurrentActiveSection,
		shouldShowATC,
		setShouldShowATC,
		sectionToOpen,
		setSectionToOpen
	};
	return <ProductStickyNavContext.Provider value={value}>{children}</ProductStickyNavContext.Provider>;
};
