import React, { type FunctionComponent } from 'react';
import { MAX_PAGE_WIDTH } from '../../../../constants/breakpoints';
import { type ContentComponent, type LinkContent, type SavingsCenterSection, type TextContent } from '../../../../types/construct.types';
import { LinkButton } from '../../../buttons/link-button/link-button.component';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';
import { MaybeBackgroundImage } from '../../../images/maybe-background-image/maybe-background-image.component';
import { addContentHandler } from '../add-content-handler';
import { backgroundImageGradientSavingsCenter, banner } from './savings-center-section-handler.css';

const MaybeCallToAction: FunctionComponent<{ cta?: TextContent; url?: LinkContent }> = ({ cta, url }) => {
	return cta ? (
		<div className="mv3 w-100 w-auto-ns">
			<LinkButton url={url?.linkUrl || ''}>{cta}</LinkButton>
		</div>
	) : null;
};

export const SavingsCenterSectionHandler: ContentComponent<SavingsCenterSection> = ({
	content: {
		fields: {
			heading,
			media_image: { image },
			media_image_overlay: { image: overlayImage },
			savings_center_sections,
			related_articles_heading,
			related_articles,
			cta,
			cta_url
		}
	},
	renderer
}) => (
	<div className="mv2">
		<MaybeBackgroundImage
			publicID={image?.id}
			options={{ width: MAX_PAGE_WIDTH, height: 200, type: image?.imageType, crop: 'fill' }}
			className={`aspect-ratio mt3 ${banner} ${backgroundImageGradientSavingsCenter}`}>
			<div className="flex items-center">
				{heading && (
					<div className={`h-100 theme-white`}>
						<h2 className="tc tl-ns absolute bottom-0 ma0 pa2 w-100 z-2">{heading}</h2>
					</div>
				)}
				<div className="right-1-ns bottom-1-ns absolute-ns center z-2">
					{overlayImage?.id && (
						<CloudinaryImage
							publicID={overlayImage.id}
							description={overlayImage.description}
							options={{ height: 100, width: 100 }}
						/>
					)}
				</div>
			</div>
		</MaybeBackgroundImage>
		<MaybeCallToAction cta={cta} url={cta_url} />
		{renderer.render(savings_center_sections)}
		{related_articles.items.length > 0 && (
			<section className="w-100">
				{related_articles_heading && <h3 className="tc">{related_articles_heading}</h3>}
				{renderer.render(related_articles, undefined, { ListContent: { totalItemCount: related_articles.items.length } })}
			</section>
		)}
		<div className="flex-ns items-end-ns w-100 w-auto-ns mt3">
			<div className="ml-auto-ns">
				<MaybeCallToAction cta={cta} url={cta_url} />
			</div>
		</div>
	</div>
);

addContentHandler('savings-center-template', 'ObjectContent', 'savings-center-section@3', SavingsCenterSectionHandler);
