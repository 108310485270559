import React from 'react';
import { type ContentComponent, type CallToActionContent } from '../../../../types/construct.types';
import { LinkButton } from '../../../buttons/link-button/link-button.component';
import { addContentHandler } from '../add-content-handler';

export const CallToAction: ContentComponent<CallToActionContent> = ({ content }) => (
	<div>
		<LinkButton url={content.fields.cta_url.linkUrl} automationHook={content.fields.cta}>
			{content.fields.cta}
		</LinkButton>
	</div>
);

addContentHandler('content-common', 'ObjectContent', 'cta', CallToAction);
