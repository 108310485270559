import React from 'react';
import {
	type ObjectContent,
	type ListContentOf,
	type ContentComponent,
	type TextContent,
	type ProductCardContent
} from '../../../../types/construct.types';
import { addContentHandler } from '../add-content-handler';

export interface DoorbusterSectionContent extends ObjectContent {
	fields: {
		doorbusters_heading: TextContent;
		doorbuster_products: ListContentOf<ProductCardContent>;
	};
}

export const DoorbusterSection: ContentComponent<DoorbusterSectionContent> = ({ content, renderer }) => {
	const doorbusterProducts = content.fields.doorbuster_products;
	return (
		doorbusterProducts.items.length > 0 && (
			<div className="w-100">
				<h4 className="tc-ns">{content.fields.doorbusters_heading}</h4>
				<div className="flex flex-wrap items-start">{renderer.render(doorbusterProducts)}</div>
			</div>
		)
	);
};

addContentHandler('doorbuster-tile-section', 'ObjectContent', 'doorbuster-tile-section', DoorbusterSection);
