import React from 'react';
import { type ContentComponent, type SupportMenuSectionContent, type SupportMenuLinkContent } from '../../../../types/construct.types';
import { StyledLink } from '../../../common-components/link/styled-link.component';
import { PanelComponent } from '../../../common-components/panel-component/panel-component.component';
import { addContentHandler } from '../add-content-handler';

export const SupportMenuSection: ContentComponent<SupportMenuSectionContent> = ({ content, renderer }) => {
	return (
		<PanelComponent headingContent={content.fields.heading} className={'f6'}>
			<>{renderer.render(content.fields.sectionItems)}</>
		</PanelComponent>
	);
};

addContentHandler('content-support-pages', 'ObjectContent', 'menu-section', SupportMenuSection);

export const SupportMenuLink: ContentComponent<SupportMenuLinkContent> = ({ content }) => {
	return (
		<StyledLink color="grey-darker" url={content.fields.cta_url.linkUrl} className="w-100" underlineHover={true}>
			{content.fields.cta}
		</StyledLink>
	);
};

addContentHandler('content-support-pages', 'ObjectContent', 'menu-link', SupportMenuLink);
