import { useMutation } from '@apollo/client';
import React, { type FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	type SubscribeToNewsletterMutation,
	type SubscribeToNewsletterMutationVariables
} from '../../../../__generated__/graphql-client-types';
import {
	emailInputRegistrationConfig,
	genericRequiredFieldRegistrationConfig
} from '../../../../helpers/form-validation-helper/form-validation-helper';
import { SUBSCRIBE_TO_NEWSLETTER } from '../../../../queries/newsletter/newsletter.queries';
import {
	type ContentComponent,
	type EmailFormSectionContent,
	type SubmittedSectionContent,
	type SubmittingSectionContent
} from '../../../../types/construct.types';
import { LinkButton } from '../../../buttons/link-button/link-button.component';
import { SubmitButton } from '../../../buttons/submit-button/submit-button.component';
import { DynamicTextInput } from '../../../inputs';
import { addContentHandler } from '../add-content-handler';
import { RichText } from '../rich-text/rich-text.handler';

type FormInputs = {
	email: string;
	firstName: string;
	lastName: string;
};

const SubmittingSection: FunctionComponent<{ content: SubmittingSectionContent; onSubmitted: () => void }> = ({ content, onSubmitted }) => {
	const { heading, description } = content.fields;
	const { register, handleSubmit } = useForm<FormInputs>();
	const [subscribeToNewsletter, { loading }] = useMutation<SubscribeToNewsletterMutation, SubscribeToNewsletterMutationVariables>(
		SUBSCRIBE_TO_NEWSLETTER
	);

	const onSubmit = async (formData: FormInputs) => {
		const { firstName, lastName, email } = formData;
		await subscribeToNewsletter({
			variables: {
				input: {
					firstName,
					lastName,
					email,
					origin: 'NONE'
				}
			}
		});
		onSubmitted();
	};
	return (
		<div>
			<h3>{heading}</h3>
			<RichText rawHtml={description.richText} />
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="flex flex-column"
				aria-label="newsletter signup form"
				data-testid="subscribe-form">
				<div className="w-100 flex mb2">
					<div className="w-50">
						<DynamicTextInput
							label="First Name"
							id="firstName"
							required={true}
							{...register('firstName', genericRequiredFieldRegistrationConfig)}
						/>
					</div>
					<div className="w-50 pl3">
						<DynamicTextInput
							label="Last Name"
							required={true}
							{...register('lastName', genericRequiredFieldRegistrationConfig)}
						/>
					</div>
				</div>
				<DynamicTextInput label="Email Address" required={true} {...register('email', emailInputRegistrationConfig)} />
				<div className="mt2">
					<SubmitButton isBusy={loading} busyText="Submitting...">
						Submit
					</SubmitButton>
				</div>
			</form>
		</div>
	);
};

const SubmittedSection: FunctionComponent<{ content: SubmittedSectionContent }> = ({ content }) => {
	const { heading, description, cta, cta_url } = content.fields;
	return (
		<div>
			<h3>{heading}</h3>
			<RichText rawHtml={description.richText} />
			{cta && (
				<div>
					<LinkButton url={cta_url.linkUrl}>{cta}</LinkButton>
				</div>
			)}
		</div>
	);
};

export const EmailFormSection: ContentComponent<EmailFormSectionContent> = ({ content }) => {
	const { submittingSection, submittedSection } = content.fields;
	const [showSubmitted, setShowSubmitted] = useState(false);
	return (
		<section className="ba b--theme-grey-light pa3 w-75 center">
			{showSubmitted ? (
				<SubmittedSection content={submittedSection} />
			) : (
				<SubmittingSection content={submittingSection} onSubmitted={() => setShowSubmitted(true)} />
			)}
		</section>
	);
};

addContentHandler('email-form-section', 'ObjectContent', 'email-form-section@1', EmailFormSection);
